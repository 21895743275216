<template>
  <v-dialog
    v-model="dialog"
    persistent
    transition="dialog-bottom-transition"
    max-width="1000"
  >
    <!--begin::Modal dialog-->
    <div class="bg-white poppins">
      <!--begin::Modal header-->
      <div class="modal-header py-4 align-center">
        <h4 class="mb-0 font-weight-bolder">Edit MailTemplate</h4>
        <!--begin::Close-->
        <div
          class="btn btn-sm btn-icon btn-active-light-info"
          @click="toggleModal"
        >
          <span class="svg-icon">
            <v-icon size="22">mdi-close</v-icon>
          </span>
        </div>
        <!--end::Close-->
      </div>
      <!--end::Modal header-->
      <!--begin::Modal body-->
      <div class="modal-body d-flex flex-column scroll-y mx-5 mx-xl-15 py-7">
        <!--begin::Body-->
        <form id="createForm" class="pa-3 pb-0">
          <v-text-field
            v-model="formData.name"
            :counter="40"
            label="Name"
            required
            dense
            outlined
            clearable
          ></v-text-field>
          <ckeditor v-model="formData.mail"></ckeditor>
        </form>
        <!--end::Body-->

        <!--begin::Actions-->
        <div class="my-4 d-flex flex-grow-1 align-end justify-center">
          <button
            type="reset"
            class="btn btn-light mr-3 px-5 py-3 ls1"
            @click="resetData"
          >
            Cancel
          </button>
          <button
            type="button"
            class="btn btn-info px-5 py-3 ls1"
            @click="submit"
          >
            Submit
          </button>
        </div>
        <!--end::Actions-->
      </div>
      <!--end::Modal body-->
    </div>
    <!--end::Modal dialog-->
  </v-dialog>
</template>

<script>
import ApiService from "@/core/services/api.service";
import Swal from "sweetalert2";

export default {
  name: "AddItem",
  props: ["refresher", "pageLoader"],
  data: () => ({
    dialog: false,
    formData: {
      mail_id: null,
      name: null,
      mail: null,
    },
  }),
  methods: {
    toggleModal() {
      if (!this.dialog) this.resetData();
      this.dialog = !this.dialog;
    },
    submit() {
      if (!this.checkValidation()) {
        return;
      }
      this.pageLoader(true);
      const data = this.formData;
      ApiService.post("/settings/mailTemplate/update", data)
        .then(() => {
          Swal.fire({
            title: "Updated",
            text: "The MailTemplate has been updated!",
            icon: "success",
            showConfirmButton: false,
            timer: 2500,
          });
          this.toggleModal();
          this.refresher();
        })
        .catch(() => {
          this.pageLoader(false);
        });
    },
    message(title, message, icon) {
      Swal.fire({
        title: title,
        text: message,
        icon: icon,
        showConfirmButton: false,
        timer: 2500,
      });
    },
    checkValidation() {
      if (!this.formData.name || this.formData.name == "") {
        this.message("Error", "Should input Name", "error");
        return false;
      } else if (!this.formData.mail || this.formData.mail == "") {
        this.message("Error", "Should input Mail", "error");
        return false;
      }
      return true;
    },
    resetData() {
      this.formData = {
        mail_id: this.$store.getters.getItemForAction.id,
        name: this.$store.getters.getItemForAction.name,
        mail: this.$store.getters.getItemForAction.mail_label.data,
      };
    },
  },
};
</script>
